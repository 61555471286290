<template>
    <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="series"
    ></apexchart>
</template>
<script>
import themeMixin from "@/mixins/theme";
export default {
    name: "apex-radial-chart",
    components: {},
    mixins: [themeMixin],
    props: {
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                colors: [],
                chart: {
                    type: "radialBar",
                    height: 100,
                    offsetY: -20,
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: "97%",
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: "#999",
                                opacity: 1,
                                blur: 2,
                            },
                            startAngle: -90,
                            endAngle: 90,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                offsetY: -2,
                                fontSize: "14px",
                                formatter: () => {
                                    return this.value.toUpperCase();
                                },
                            },
                        },
                    },
                },
                grid: {
                    padding: {
                        top: -10,
                    },
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "light",
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91],
                    },
                },
                labels: ["Average Results"],
            },
        };
    },
    computed: {
        series() {
            let value = 0;
            switch (this.value) {
                case "Poor":
                    value = 27;
                    break;
                case "Fair":
                    value = 57;
                    break;
                case "Good":
                    value = 87;
                    break;
                default:
                    break;
            }
            return [value];
        },
        // Can be vuetify style colors i.e. primary darken-2 or accent lighten-1
        colorPalette() {
            switch (this.value) {
                case "Poor":
                    return "error";
                case "Fair":
                    return "warning";
                case "Good":
                    return "secondary";
                default:
                    return "#eee";
            }
        },
    },
    watch: {},
    created() {
        this.chartOptions.colors = this.themePalette(this.colorPalette);
    },
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>